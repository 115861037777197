<template>
   <div class="page_section login_page">
      <MobileTitle :title="'Passwort zurücksetzen'"/>

      <div class="content_element timetable_element">
         <vue-form :state="formstate" @submit.prevent="resetPassword" id="registerForm">
            <div class="timetable_wrapper">
               <div class="timetable_inner_wrapper">
                  <h5 class="timetable_title">Passwort zurücksetzen</h5>
                  <div class="login_wrapper">
                     <validate tabindex="5" :custom="{passwordStrength: passwordStrength}">
                        <div class="login_password_wrapper">
                                <span class="icon">
                                        <i class="ab-icon-lock"></i>
                                    </span>
                           <InputText
                              name="password"
                              placeholder="Passwort"
                              inputId="registerPassword"
                              v-model="model.password"
                              type="password"
                              :required="true"
                              :disabled="false"
                           />
                        </div>
                        <field-messages name="password" class="vf-error_message">
                           <template slot="required" slot-scope="state">
                                        <span
                                           v-if="state.$dirty || state.$submitted">{{'Password'}} is a required field</span>
                           </template>
                           <template slot="passwordStrength" slot-scope="state">
                              <span v-if="state.$dirty || state.$submitted">{{'Password'}} requires UpperCase, LowerCase, Number/SpecialChar and min 8 Chars</span>
                           </template>
                        </field-messages>
                     </validate>

                     <validate tabindex="5" :custom="{matches: matches}">
                        <div class="login_password_wrapper">
                                <span class="icon">
                                        <i class="ab-icon-lock"></i>
                                    </span>
                           <InputText
                              name="passwordConfirmation"
                              placeholder="Passwort Bestätigung"
                              inputId="registerPasswordConfirmation"
                              v-model="model.passwordConfirmation"
                              type="password"
                              :required="true"
                              :disabled="false"
                           />
                        </div>
                        <field-messages name="passwordConfirmation" class="vf-error_message">
                           <template slot="required" slot-scope="state">
                              <span v-if="state.$dirty || state.$submitted">{{'Passwort Bestätigung'}} is a required field</span>
                           </template>
                           <template slot="matches" slot-scope="state">
                              <span v-if="state.$dirty || state.$submitted">Passwords do not match</span>
                           </template>
                        </field-messages>
                     </validate>
                     <div class="register_links">
                        <a href="#" @click.prevent="$store.commit('globalPopup/setLoginOpen', true)">
                           Anmelden
                        </a>
                     </div>

                     <div class="timetable_submit_wrapper d-flex flex-column align-items-center">
                        <button class="btn btn-grey" :disabled="formSending">
                           <template v-if="formSending">{{ __t('send') }} ...</template>
                           <template v-else>{{ __t('send') }}</template>
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </vue-form>
      </div>

      <Footer/>
   </div>
</template>

<script>
   import {mapState} from 'vuex';
   import Footer from "../_common/Footer";
   import MobileTitle from "../_common/MobileTitle";
   import {
      InputText
   } from '../../components/_form';

   export default {
      name: "ResetPassword",
      data() {
         return {
            model: [],
            formstate: {},
            formSending: false,
         }
      },
      components: {
         MobileTitle,
         Footer,
         InputText
      },
      computed: {
         ...mapState([
            'alert'
         ])
      },
      methods: {
         matches: function (value) {
            if (!this.model.password) {
               return true;
            }
            return value === this.model.password;
         },
         passwordStrength(value) {
            return /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(value);
         },
         resetPassword() {
            if (this.formstate.$invalid) {
               document.getElementsByClassName('vf-field-invalid')[0].focus();
               return;
            }
            this.formSending = true;
            this.$store.dispatch('authentication/resetPassword', {
               code: this.$route.params.code,
               password: this.model.password,
               password_confirmation: this.model.passwordConfirmation
            });
         }
      },
      watch: {
         'alert.type': function (val) {
            this.formSending = false;
            if (val === 'alert-success') {
               this.$router.push('/login');
            }
         }
      }
   }
</script>

<style scoped lang="scss">

</style>
